export const USER_ROLES = {
    ADMIN: 'admin',
    PROVIDER: 'provider',
    CUSTOMER: 'customer',
    MANAGER: 'manager',
} as const;

export const PROVIDER_USER_STATUSES = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
} as const;

export const PROVIDER_USER_GENDERS = {
    MALE: 'male',
    FEMALE: 'female',
    OTHER: 'other',
};

export const PROVIDER_USER_STATUS_OPTIONS = (t: Function) => Object.entries(PROVIDER_USER_STATUSES).map(([_, value]) => ({ label: t(`providerUserStatuses.${value}`), value })) as TDropdownOption[];
export const PROVIDER_USER_GENDER_OPTIONS = (t: Function) => Object.entries(PROVIDER_USER_GENDERS).map(([_, value]) => ({ label: t(`providerUserGenders.${value}`), value })) as TDropdownOption[];
